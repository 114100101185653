<template>
    <div class="landing">
        <Header/>
        <AboutSection/>
        <Footer/>
    </div>
</template>

<script>

import Header from "./static/sections/Header.vue"
import AboutSection from "./static/about-sections/AboutSection.vue"
import Footer from "./static/sections/Footer.vue"

export default {
    name: 'about',
    components: {
        Header,
        AboutSection,
        Footer
    },
}
</script>
