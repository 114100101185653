<template>
    <div class="container about">
        <div class="about__img-wrap about__box-img">
            <picture>
                <source srcset="/img/about/about-picture.webp" type="image/webp">
                <source srcset="/img/about/about-picture.jpg" type="image/jpeg">
                <img class="about__img" width="620" height="574" src="/img/about/about-picture.jpg" alt="About">
            </picture>
        </div>

        <div class="about__text-wrap">
            <div class="about__title">
                <h2 class="about__title-h2">{{ $t('general.about-us') }}</h2>
                <p class="about__title-p">
                    {{ $t('general.nomadic-soft-stands') }}
                </p>
                <p class="about__title-p">
                    {{ $t('general.for-besteller') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutSection"
}
</script>

<style scoped lang="scss">
@import "../../../scss/mixins";

.container {
    padding: 0;

    @include lg-block() {
        max-width: 97vw;
    }
    @include sm-block() {
        max-width: 96vw;
    }
    @media screen and(width < 600px) {
        max-width: 95vw;
    }
    @media screen and(width < 450px) {
        max-width: 92.5vw;
    }
}

.about {
    margin-top: 40px;
    background: #f3f1f1;
    display: flex;
    justify-content: center;
    @media (min-width: 768px) {
        max-height: 574px;
    }
    border-radius: 10px;

    @include lg-block() {
        flex-wrap: wrap;
        margin: 16px auto;
    }

    &__text-wrap {
        padding: 147px 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 1100px) {
            padding: 80px 50px;
        }
        @media (min-width: 768px) {
            flex: 1;
        }
        @media (max-width: 767px) {
            padding: 24px;
        }
    }

    &__title {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 95%;
        gap: 16px;

        @include sm-block() {
            max-width: 100%;
        }

        .about__title-h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            color: #000;

            @include lg-block() {
                font-size: 22px;
            }
        }

        .about__title-p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #000;

            @include lg-block() {
                font-size: 16px;
            }
        }
    }

    &__img-wrap {
        min-width: 50%;
        height: 574px;
        @media (max-width: 767px) {
            min-width: 100%;
            height: auto;
        }
        @media (min-width: 768px) {
            flex: 1;
        }
    }

    &__img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 10px 0 0 10px;
        @media (max-width: 767px) {
            border-radius: 10px 10px 0 0;
        }
    }
}
</style>

